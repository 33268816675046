import { pick } from 'lodash-es';
import { IEmployeeEditFormValues } from 'modules/settings/submodules/employees/store/model';
import { IUpdateUserPayload } from 'shared/models/User';

export function getUpdateEmployeePayload(
    id: string,
    formValues: IEmployeeEditFormValues,
    initialFormValues: IEmployeeEditFormValues,
): IUpdateUserPayload {
    const updates = pick(formValues, [
        'first_name',
        'last_name',
        'address2',
        'city',
        'state',
        'county',
        'zip_code',
        'external_employee_id',
    ]);
    return {
        id,
        data: {
            ...updates,
            address1: formValues.address,
            cell_phone: formValues.phone,
            email: formValues.email === initialFormValues.email ? undefined : formValues.email,
        },
    };
}
