import React, { useMemo } from 'react';
import { get, isUndefined } from 'lodash-es';
import { EafRetroIcon } from 'modules/eafApproval/components/EafRetroIcon';
import {
    EafReasonKey,
    EafType,
    eafTypeDisplayValues,
    IEafApproverChangeData,
    IEafCompensationChangeData,
    IEafEndDateChangeData,
    IEafPromotionDemotion,
    IEafStartDateChangeData,
    IEafTransferLocationData,
    IEmployeeActionForm,
} from 'modules/employeeActionsForm/store/models';
import { selectEafReasons } from 'modules/employeeActionsForm/store/selectors';
import { selectEmployeeProfilesByIds } from 'modules/offerLetter/store/selectors';
import { getApproverNames } from 'modules/subassignmentManagement/components/EafHistory/useEafChangeString';
import { useSelector } from 'react-redux';
import { ISheetInfoPureProps, SheetInfoPure } from 'shared/components/sidebars/SheetDetail/SheetInfoPure';
import { getUserName } from 'shared/utils/converters/user';
import { formatShortDate } from 'shared/utils/formatters/dateFormatter';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { pluralize } from 'shared/utils/formatters/pluralize';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { selectAssignmentsById, selectSubassignmentsByIds } from 'store/entities/configuration/configurationSelectors';
import { CustomFieldType, ICustomFieldValue } from 'store/entities/customFields/model';
import { selectCustomFieldsByIds, selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';
import { getGroupedCustomFieldDisplayValues } from 'store/entities/customFields/utils';
import { selectUsersById } from 'store/entities/users/selectors';
import { Nullable } from 'types/types';

interface ISheetInfoProps {
    eaf: IEmployeeActionForm;
    isLoading?: boolean;
}

const useTypeSpecificBlocks = (eaf: IEmployeeActionForm) => {
    const customFieldValuesByIds = useSelector(selectCustomFieldValuesByIds);
    const usersByIds = useSelector(selectUsersById);

    const { employee_action_form_data: eafData } = eaf;
    const eafType: EafType = eafData.employee_action_form_type;
    switch (eafType) {
        case EafType.CompensationChange: {
            return [
                {
                    title: 'Original Base Rate',
                    value: formatDollars((eafData as IEafCompensationChangeData).old_base_pay_rate),
                },
                {
                    title: 'New Base Rate',
                    value: formatDollars((eafData as IEafCompensationChangeData).new_base_pay_rate),
                },
            ];
        }
        case EafType.Promotion:
        case EafType.Demotion: {
            const {
                old_base_pay_rate: oldRate,
                new_base_pay_rate: newRate,
                old_position_custom_field_value_id: oldPositionId,
                new_position_custom_field_value_id: newPositionId,
            } = eafData as IEafPromotionDemotion;

            const oldPosition: Nullable<ICustomFieldValue> = customFieldValuesByIds[oldPositionId];
            const newPosition: Nullable<ICustomFieldValue> = customFieldValuesByIds[newPositionId];

            const oldValue = [oldPosition?.data?.name, formatDollars(oldRate)].filter(Boolean).join(' – ');
            const newValue = [newPosition?.data?.name, formatDollars(newRate)].filter(Boolean).join(' – ');

            return [
                {
                    title: 'Original Position / Rate',
                    value: oldValue,
                },
                {
                    title: 'New Position / Rate',
                    value: newValue,
                },
            ];
        }
        case EafType.TransferLocation: {
            const {
                old_location_custom_field_value_id: oldId,
                new_location_custom_field_value_id: newId,
            } = eafData as IEafTransferLocationData;
            const oldValue: Nullable<ICustomFieldValue> = customFieldValuesByIds[oldId];
            const newValue: Nullable<ICustomFieldValue> = customFieldValuesByIds[newId];
            return [
                {
                    title: 'Original Location',
                    value: oldValue?.data?.name,
                },
                {
                    title: 'New Location',
                    value: newValue?.data?.name,
                },
            ];
        }
        case EafType.StartDateChange: {
            const {
                old_start_date: oldStartDate,
                new_start_date: newStartDate,
            } = eafData as IEafStartDateChangeData;
            return [
                {
                    title: 'Original Start Date',
                    // @ts-ignore
                    value: formatShortDate(oldStartDate),
                },
                {
                    title: 'New Start Date',
                    value: formatShortDate(newStartDate),
                },
            ];
        }
        case EafType.EndDateChange: {
            const {
                old_end_date: oldEndDate,
                new_end_date: newEndDate,
            } = eafData as IEafEndDateChangeData;
            return [
                {
                    title: 'Original End Date',
                    value: oldEndDate ? formatShortDate(oldEndDate) : 'Empty',
                },
                {
                    title: 'New End Date',
                    value: newEndDate ? formatShortDate(newEndDate) : 'Empty',
                },
            ];
        }
        case EafType.ApproverChange: {
            const {
                old_managers: oldManagers,
                new_managers: newManagers,
            } = eafData as IEafApproverChangeData;
            return [
                {
                    title: `Original ${pluralize('Approver', oldManagers.length)}`,
                    value: getApproverNames(oldManagers, usersByIds),
                },
                {
                    title: `New ${pluralize('Approver', newManagers.length)}`,
                    value: getApproverNames(newManagers, usersByIds),
                },
            ];
        }
        default:
            return [];
    }
};

const useEafAssignmentAndSubassignment = (eaf: IEmployeeActionForm) => {
    const assignmentsByIds = useSelector(selectAssignmentsById);
    const subassignmentsByIds = useSelector(selectSubassignmentsByIds);
    const assignment = assignmentsByIds[eaf?.assignment_id];
    const subassignment = subassignmentsByIds[eaf?.subassignment_id];
    return [assignment, subassignment];
};

const useCustomFieldBlocks = (eaf: IEmployeeActionForm) => {
    const customFieldValuesByIds = useSelector(selectCustomFieldValuesByIds);
    const customFieldByIds = useSelector(selectCustomFieldsByIds);
    const [assignment, subassignment] = useEafAssignmentAndSubassignment(eaf);

    const { employee_action_form_data: eafData } = eaf;
    const eafType: EafType = eafData.employee_action_form_type;

    const customFieldValueIds = subassignment?.custom_field_value_ids
        || assignment?.custom_field_value_ids
        || [];
    const allCustomFieldIds = [
        ...(subassignment?.all_parent_custom_field_ids || []),
        ...(subassignment?.all_values_custom_field_ids || []),
    ];
    const groupedCustomFieldValues = getGroupedCustomFieldDisplayValues(
        customFieldValueIds,
        allCustomFieldIds,
        customFieldValuesByIds,
    );

    const customFieldBlockEntries = Object.entries(groupedCustomFieldValues)
        .filter(([fieldId]) => {
            const field = customFieldByIds[fieldId];
            switch (eafType) {
                case EafType.TransferLocation:
                    return field?.headway_connect_field?.key !== CustomFieldType.Location;
                case EafType.Promotion:
                case EafType.Demotion:
                    return field?.headway_connect_field?.key !== CustomFieldType.Position;
                default:
                    return true;
            }
        });

    return useMemo(() => {
        return customFieldBlockEntries.map(([fieldId, fieldValues]) => {
            const value = fieldValues.filter(Boolean).join(', ');
            const field = customFieldByIds[fieldId];
            return {
                title: field?.name,
                value,
            };
        }) || [];
    }, [customFieldBlockEntries, customFieldByIds]);
};

const EafInfo = separateLogicDecorator<ISheetInfoProps, ISheetInfoPureProps>(
    ({ eaf, isLoading }: ISheetInfoProps) => {
        const usersByIds = useSelector(selectUsersById);
        const reasons = useSelector(selectEafReasons);
        const [assignment] = useEafAssignmentAndSubassignment(eaf);

        const employeeProfile = useSelector(selectEmployeeProfilesByIds)[assignment?.employee_profile_id || ''];
        const customFieldBlocks = useCustomFieldBlocks(eaf);
        const typeSpecificBlocks = useTypeSpecificBlocks(eaf);

        const data = useMemo(() => {
            const { employee_action_form_data: eafData } = eaf;
            const eafReason: EafReasonKey = get(eafData, 'employee_action_form_reason');
            const eafType: EafType = eafData.employee_action_form_type;

            const reason = reasons.find(item => item.key === eafReason);
            const manager = usersByIds[eaf.created_by];

            return [
                {
                    title: 'Employee ID',
                    value: employeeProfile?.avionte_talent_id ?? employeeProfile?.prism_employee_id,
                },
                {
                    title: 'Type',
                    value: eafTypeDisplayValues[eafType],
                },
                ...customFieldBlocks,
                ...typeSpecificBlocks,
                {
                    title: 'Edited by',
                    value: getUserName(manager),
                },
                {
                    title: 'Reason',
                    value: reason?.text,
                },
                {
                    title: 'Reason',
                    // @ts-ignore
                    value: eafData?.employee_action_form_reason_text,
                },
                {
                    title: 'Eligible for rehire',
                    // @ts-ignore
                    value: isUndefined(eafData?.eligible_for_rehire) ? null
                        // @ts-ignore
                        : eafData?.eligible_for_rehire ? 'Yes' : 'No',
                },
                {
                    title: 'Rejection Reason',
                    value: eaf.approval_rejection_reason,
                },
                {
                    title: 'Effective Date',
                    // @ts-ignore
                    value: formatShortDate(eafData.effective_date),
                    additional: eafData.is_retro ? <EafRetroIcon alignSelf="center" /> : undefined,
                },
            ];
        }, [reasons, usersByIds, eaf, employeeProfile?.prism_employee_id, employeeProfile?.avionte_talent_id,
            customFieldBlocks, typeSpecificBlocks]);
        return { data, isLoading };
    },
)(SheetInfoPure);

export default EafInfo;
