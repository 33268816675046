import { Button, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { setOpenBulkCreateSubassignmentModal, setOpenBulkCreateSubassignmentUploadModal } from 'modules/subassignmentManagement/components/CreateSubAssignment/store/actions';
import { selectCheckedAssignmentIds } from 'modules/subassignmentManagement/components/SubassignmentTable/store/selectors';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

const useStyles = makeHighPriorityStyles(() => ({
    button: {
        padding: '8px 0px 8px 8px',
        alignItems: 'flex-start',
    },
    icon: {
        height: '20px',
    },
    menu: {
        marginTop: '46px',
    },
}));

export const BulkCreateV2Button = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const checkedAssignmentIds = useSelector(selectCheckedAssignmentIds);
    const hasSelectedAssignments = Boolean(checkedAssignmentIds.length);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCreateClick = useCallback(() => {
        dispatch(setOpenBulkCreateSubassignmentModal(true));
        handleClose();
    }, [dispatch]);
    const handleCreateUploadClick = useCallback(() => {
        dispatch(setOpenBulkCreateSubassignmentUploadModal(true));
        handleClose();
    }, [dispatch]);

    return (
        <>
            <Button
                variant="contained"
                color="secondary"
                onClick={handleClick}
                title={hasSelectedAssignments ? '' : 'Please choose employee'}
                className={classes.button}
            >
                Bulk Create <ArrowDropDownIcon className={classes.icon} />
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className={classes.menu}
            >
                <MenuItem
                    onClick={handleCreateClick}
                    disabled={!hasSelectedAssignments}
                    title={hasSelectedAssignments ? undefined : 'Please select employees'}
                >
                    Manual Entry
                </MenuItem>
                <MenuItem onClick={handleCreateUploadClick}>Import</MenuItem>
            </Menu>
        </>
    );
};
