import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import clsx from 'clsx';
import { FormikProps } from 'formik';
import { StatusSelect } from 'modules/settings/common/components/StatusSelect/StatusSelect';
import {
    LocationsFormDataSection,
} from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValueForm/LocationsFormDataSection';
import {
    ICustomFieldValueFormValues,
} from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValueForm/model';
import {
    NameCodeFormDataSection,
} from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValueForm/NameCodeFormDataSection';
import {
    NameValueDataSection,
} from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValueForm/NameValueDataSection';
import {
    ProjectClassFieldValuesSelect,
} from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValueForm/ProjectClassFieldValuesSelect';
import {
    RelationFormSection,
} from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValueForm/RelationFormSection';
import {
    PositionCustomFieldDataSection,
} from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValueForm/sections/PositionCustomFieldDataSection';
import {
    SCACustomFieldValueDataSection,
} from 'modules/settings/submodules/clients/customFieldValues/components/CustomFieldValueForm/sections/SCACustomFieldValueDataSection';
import { IRelationsByHierarchyType } from 'modules/settings/submodules/clients/customFieldValues/store/selectors';

import {
    formBasicSpacing,
    useSettingsFormStyles,
} from 'modules/settings/submodules/components/sharedStyles/formStyles';
import React, { useEffect } from 'react';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import EnumFormSelect from 'shared/components/formFields/EnumFormSelect';
import { FormMode } from 'shared/models/FormMode';
import {
    CustomFieldType,
    DifferentialMethod,
    differentialMethodDisplayValues,
    ILocationCustomField,
} from 'store/entities/customFields/model';
import { AvionteJobOrdderFieldValueDataSection } from './sections/AvionteJodOrderCustomFieldValueDataSection';

export interface ICustomFieldValueFormProps extends FormikProps<ICustomFieldValueFormValues> {
    isLoading?: boolean;
    isDisabled?: boolean;
    submitTitle: string;
    onCancel?: () => void;
    mode: FormMode;
    type: CustomFieldType;
    relations: IRelationsByHierarchyType;
    compatible?: boolean;
}

export function CustomFieldValueForm({
    handleSubmit,
    submitTitle,
    relations,
    type,
    mode,
    setFieldValue,
    isLoading = false,
    isDisabled = false,
    compatible = false,
    values,
}: ICustomFieldValueFormProps) {
    const classes = useSettingsFormStyles();
    const [tab, setTab] = React.useState('1');

    const handleChangeTab = (event: React.ChangeEvent<{}>, newTab: number) => {
        setTab(`${newTab}`);
    };

    useEffect(() => {
        if (type === CustomFieldType.Location && mode === FormMode.New) {
            const { state_code } = values?.data as ILocationCustomField;
            if (state_code) {
                setFieldValue('data.work_comp_state_code', state_code);
            }
        }
    }, [setFieldValue, type, mode, values?.data]);

    const relationsTabTitle = compatible ? 'Assignment & Offer Relations' : 'Assignment Relations';

    return (
        <form onSubmit={handleSubmit} className={clsx(classes.form, classes.noPadding)}>
            <TabContext value={tab}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={tab}
                        onChange={handleChangeTab}
                        classes={{
                            indicator: classes.tabIndicator,
                        }}
                    >
                        <Tab label="Value" value="1" />
                        {relations.assignment && <Tab label={relationsTabTitle} value="2" />}
                        {relations.offerLetter && <Tab label="Offer Letter Relations" value="3" />}
                    </Tabs>
                </AppBar>
                <TabPanel value="1">
                    <Box className={classes.formBlock}>
                        <Typography variant="subtitle2" className={classes.formBlockTitle}>Value</Typography>
                        {type === CustomFieldType.Location && (
                            <LocationsFormDataSection
                                mode={mode}
                                setFieldValue={setFieldValue}
                                path="data."
                            />
                        )}
                        {(type === CustomFieldType.NameCode || type === CustomFieldType.NameCodeDescription) && (
                            <NameCodeFormDataSection
                                mode={mode}
                                path="data."
                                useDescription={type === CustomFieldType.NameCodeDescription}
                            />
                        )}
                        {(type === CustomFieldType.NameCodeDescriptionProjectClass) && (
                            <NameCodeFormDataSection
                                mode={mode}
                                path="data."
                                useDescription
                                extensionFields={(
                                    <Box display="flex">
                                        <Box width="60%">
                                            <ProjectClassFieldValuesSelect
                                                label="Project Class"
                                                name="data.project_class"
                                                className={classes.input}
                                                useIdValue
                                            />
                                        </Box>
                                    </Box>
                                )}
                            />
                        )}
                        {(type === CustomFieldType.ShiftCode) && (
                            <NameCodeFormDataSection
                                mode={mode}
                                path="data."
                                useDescription
                                extensionFields={(
                                    <Box display="flex">
                                        <Box width="60%">
                                            <EnumFormSelect
                                                name="data.differential_method"
                                                values={DifferentialMethod}
                                                className={classes.input}
                                                label="Differential Method"
                                                displayValues={differentialMethodDisplayValues}
                                            />
                                        </Box>
                                    </Box>
                                )}
                            />
                        )}
                        {type === CustomFieldType.Position && (
                            <PositionCustomFieldDataSection
                                path="data."
                                mode={mode}
                            />
                        )}
                        {type === CustomFieldType.NameValue && (
                            <NameValueDataSection />
                        )}
                        {type === CustomFieldType.SCA && (
                            <SCACustomFieldValueDataSection path="data." />
                        )}
                        {type === CustomFieldType.AvionteJobOrder && (
                            <AvionteJobOrdderFieldValueDataSection path="data." />
                        )}
                        <Box className={classes.formBlock}>
                            <Box display="flex">
                                <Box width="60%">
                                    <StatusSelect
                                        name="status"
                                        label="Status"
                                        className={classes.input}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </TabPanel>

                {relations.assignment && (
                    <TabPanel value="2">
                        <RelationFormSection relations={relations.assignment} fieldNamePrefix="assignment" />
                    </TabPanel>
                )}

                {relations.offerLetter && (
                    <TabPanel value="3">
                        <RelationFormSection relations={relations.offerLetter} fieldNamePrefix="offerLetter" />
                    </TabPanel>
                )}
            </TabContext>

            <Box m={formBasicSpacing + 2}>
                <ButtonWithLoader
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    disabled={isDisabled}
                    isLoading={isLoading}
                    data-testid="submit_update_button"
                >
                    {submitTitle}
                </ButtonWithLoader>
            </Box>
        </form>
    );
}
