import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import MainContent from 'shared/components/common/MainContent';
import { loadClientRoles, loadGlobalRoles } from 'store/entities/users/actions';
import { getClientUsersAssociation } from 'modules/settings/submodules/platformUsers/store/actions';
import { UserClientAssociationTable } from 'modules/settings/submodules/platformUsers/components/ClientUserAssociationTable/UserClientAssociationTable';
import {
    CreateUserClientAssociationButton,
} from 'modules/settings/submodules/platformUsers/components/CreateUserClientAssociation/CreateUserClientAssociationButton';
import {
    ClientUserAssociationFilterInline,
} from 'modules/settings/submodules/platformUsers/components/ClientUserAssociationFilter/ClientUserAssociationFilterInline';

export const UserRolesPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadClientRoles.init());
        dispatch(loadGlobalRoles.init());
        dispatch(getClientUsersAssociation.init());
    }, [dispatch]);

    return (
        <MainContent whiteBackground>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
            >
                <CreateUserClientAssociationButton />
                <ClientUserAssociationFilterInline />
            </Box>
            <UserClientAssociationTable />
        </MainContent>
    );
};
