import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { createEmployeeActionForm } from 'modules/employeeActionsForm/store/actions';
import { EafType } from 'modules/employeeActionsForm/store/models';
import { selectIsEafCreating } from 'modules/employeeActionsForm/store/selectors';
import { CompensationChangeForm } from 'modules/subassignmentManagement/components/CompensationChange/CompensationChangeForm';
import { setOpenCompensationChange } from 'modules/subassignmentManagement/components/CompensationChange/store/actions';
import { ICompensationChangeFormValues } from 'modules/subassignmentManagement/components/CompensationChange/store/model';
import { selectIsCompensationChangeOpened } from 'modules/subassignmentManagement/components/CompensationChange/store/selectors';
import { useAssignmentFromDetails } from 'modules/subassignmentManagement/components/CompensationChange/useAssignmentFromDetails';
import { useCompensationChangeData } from 'modules/subassignmentManagement/components/CompensationChange/useCompensationChangeData';
import { WorkspaceSidebar } from 'shared/components/workspaces/WorkspaceSidebar/WorkspaceSidebar';
import { selectClientPaySettings } from 'store/entities/clients/selectors/timeAndPaySelectors';

export const CompensationChange = () => {
    const dispatch = useDispatch();

    const isOpen = useSelector(selectIsCompensationChangeOpened);
    const { assignment, subassignment } = useAssignmentFromDetails();
    const compensationChangeFormProps = useCompensationChangeData(assignment, subassignment);
    const paySettings = useSelector(selectClientPaySettings);
    const isLoading = useSelector(selectIsEafCreating);
    const startDate = useMemo(() => {
        return subassignment?.start_date ?? assignment?.hire_date;
    }, [assignment, subassignment]);

    const onClose = useCallback(() => {
        dispatch(setOpenCompensationChange(false));
    }, [dispatch]);

    const handleSubmit = useCallback((values: ICompensationChangeFormValues) => {
        dispatch(createEmployeeActionForm.init({
            assignment_id: assignment.id,
            subassignment_id: subassignment?.id,
            // @ts-ignore
            employee_action_form_data: {
                employee_action_form_type: EafType.CompensationChange,
                employee_action_form_reason: values.reason,
                effective_date: values.effectiveDate,
                new_base_pay_rate: values.newPayRate,
                // @ts-ignore
                is_retro: values.isRetro,
            },
        }));
    }, [subassignment, assignment?.id, dispatch]);

    return (
        <WorkspaceSidebar
            title="Compensation change"
            isOpened={isOpen}
            onClose={onClose}
        >
            <Box m={3}>
                <CompensationChangeForm
                    isLoading={isLoading}
                    onSubmit={handleSubmit}
                    onCancel={onClose}
                    // @ts-ignore
                    paySettings={paySettings}
                    startDate={startDate}
                    {...compensationChangeFormProps}
                />
            </Box>
        </WorkspaceSidebar>
    );
};
