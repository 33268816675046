import React, { useCallback, useEffect, useMemo } from 'react';
import { FormikProps } from 'formik';
import { isEqual, isNumber, omit, toString } from 'lodash-es';
import { DealAutocompleteWithSearch } from 'shared/components/autocomplete/deals/DealAutocompleteWithSearch';
import NumberField from 'shared/components/formFields/NumberField';
import { pphFilterDefaultValues } from 'modules/payrollProcessorHub/store/reducer';
import { Permission } from 'store/components/auth/authModels';
import { IPayrollProcessorFilters, PayrollSheetTabs } from 'modules/payrollProcessorHub/store/model';
import PayrollPayPeriodSelect from 'modules/payrollProcessorHub/components/PayPeriodSelect/PayrollPayPeriodSelect';
import { UserAutocompleteWithSearch } from 'shared/components/autocomplete/UserAutocompleteWithSearch/UserAutocompleteWithSearch';
import { debounce } from 'ts-debounce';
import { useHeaderFormStyles } from './styles';
import { useFiltersStyles } from 'shared/styles/filtersStyles';
import ClientAutocomplete from '../../../../shared/components/selects/ClientAutocomplete';
import CustomFieldNamedAutocomplete from '../../../../shared/components/selects/CustomFieldNamedAutocomplete';
import { ICustomFieldValue } from '../../../../store/entities/customFields/model';
import { shortDateFormat } from '../../../../shared/models/Dates';
import DayPickerField from 'shared/components/formFields/DayPickerField';

export interface IPayrollFilterFormProps extends FormikProps<IPayrollProcessorFilters> {
    onChange: (value: Partial<IPayrollProcessorFilters>) => void;
    onChangeClient?: (clientId: string | null) => void;
    actualFilterValues: IPayrollProcessorFilters;
    isDashboard?: boolean;
    clientHasJobNumberConfiguration?: boolean;
    clientDepartmentValues?: ICustomFieldValue[];
    clientIsAvionteBound?: boolean;
}

export const PayrollFilterForm = ({
    actualFilterValues,
    handleSubmit,
    onChange,
    values,
    setFieldValue,
    onChangeClient,
    clientIsAvionteBound,
    isDashboard = false,
    clientHasJobNumberConfiguration = false,
    clientDepartmentValues = [],
}: IPayrollFilterFormProps) => {
    const headerFormClasses = useHeaderFormStyles();
    const formClasses = useFiltersStyles();
    const classes = isDashboard ? headerFormClasses : formClasses;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onFilterChange = useCallback(debounce(
        (newValues: IPayrollProcessorFilters) => {
            onChange({
                clientId: newValues.clientId || pphFilterDefaultValues.clientId,
                payPeriodEnd: newValues.payPeriodEnd || pphFilterDefaultValues.payPeriodEnd,
                employeeId: newValues.employeeId || pphFilterDefaultValues.employeeId,
                assignmentId: newValues.assignmentId || pphFilterDefaultValues.assignmentId,
                payrollProcessorUserId: newValues.payrollProcessorUserId
                    || pphFilterDefaultValues.payrollProcessorUserId,
                dealId: newValues.dealId || pphFilterDefaultValues.dealId,
                jobNumber: newValues.jobNumber !== '' ? toString(newValues.jobNumber) : pphFilterDefaultValues.jobNumber,
                managerIds: newValues.managerIds || pphFilterDefaultValues.managerIds,
                custom_fields: newValues.custom_fields || pphFilterDefaultValues.custom_fields,
                last_editor: newValues.last_editor || pphFilterDefaultValues.last_editor,
                batch_id: newValues.batch_id,
                pay_date: newValues.pay_date,
            });
        },
        300,
    ), [onChange]);
    useEffect(() => {
        if (!isEqual(omit(values, 'status'), omit(actualFilterValues, 'status'))) {
            onFilterChange(values);
        }
    }, [actualFilterValues, values, onChange, onFilterChange]);
    useEffect(() => {
        const { dealId, jobNumber } = values;
        if (isNumber(jobNumber)) {
            // Reset job number value after entering negative value
            setFieldValue('jobNumber', pphFilterDefaultValues.jobNumber);
        }
        if ((!clientHasJobNumberConfiguration || isDashboard) && (dealId || jobNumber)) {
            setFieldValue('dealId', pphFilterDefaultValues.dealId);
            setFieldValue('jobNumber', pphFilterDefaultValues.jobNumber);
        }
    }, [clientHasJobNumberConfiguration, values, setFieldValue, isDashboard]);

    const { status } = values;
    useEffect(() => {
        if (status !== actualFilterValues.status) {
            setFieldValue('batch_id', pphFilterDefaultValues.batch_id);
            setFieldValue('pay_date', pphFilterDefaultValues.pay_date);
        }
    }, [actualFilterValues.status, setFieldValue, status]);
    const { clientId } = values;
    useEffect(() => {
        if (clientId !== actualFilterValues.clientId) {
            onChangeClient && onChangeClient(clientId);
            setFieldValue('assignmentId', pphFilterDefaultValues.assignmentId);
            setFieldValue('payrollProcessorUserId', pphFilterDefaultValues.payrollProcessorUserId);
            setFieldValue('managerIds', pphFilterDefaultValues.managerIds);
            setFieldValue('employeeId', pphFilterDefaultValues.employeeId);
            setFieldValue('dealId', pphFilterDefaultValues.dealId);
            setFieldValue('batch_id', pphFilterDefaultValues.batch_id);
            setFieldValue('pay_date', pphFilterDefaultValues.pay_date);
        }
    }, [actualFilterValues.clientId, clientId, onChangeClient, setFieldValue]);

    useEffect(() => {
        onChangeClient && onChangeClient(clientId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onChangeClient]);

    const isFullTableStatus = useMemo(() => {
        return actualFilterValues.status === PayrollSheetTabs.APPROVED
            || actualFilterValues.status === PayrollSheetTabs.CLOSED
            || actualFilterValues.status === PayrollSheetTabs.ALL;
    }, [actualFilterValues.status]);

    const isClosedStatus = useMemo(() =>
        actualFilterValues.status === PayrollSheetTabs.CLOSED, [actualFilterValues.status]);

    return (
        <form className={classes.form} onSubmit={handleSubmit}>
            <ClientAutocomplete
                name="clientId"
                label="Client"
                useIdValue
                className={classes.input}
            />
            <PayrollPayPeriodSelect
                name="payPeriodEnd"
                status={actualFilterValues.status}
                useIdValue
                className={classes.input}
                resetIfValueNotAvailable
            />
            {isDashboard ? (
                <UserAutocompleteWithSearch
                    additionalFilter={{
                        purpose: Permission.payrollProcessing,
                        client_id: clientId || undefined,
                    }}
                    name="payrollProcessorUserId"
                    label="Payroll Processor"
                    useIdValue
                    className={classes.input}
                />
            ) : (
                <UserAutocompleteWithSearch
                    additionalFilter={{
                        purpose: Permission.SubmitSheets,
                        client_id: clientId || undefined,
                    }}
                    name="employeeId"
                    label="Find an employee"
                    useIdValue
                    className={classes.input}
                />
            )}
            {!isDashboard && (
                <>
                    <UserAutocompleteWithSearch
                        additionalFilter={{
                            purpose: Permission.ApproveSheets,
                            client_id: clientId || undefined,
                        }}
                        name="managerIds"
                        label="Search manager"
                        useIdValue
                        className={classes.input}
                        multiple
                    />
                </>
            )}
            {!isDashboard && isFullTableStatus && (
                <UserAutocompleteWithSearch
                    additionalFilter={{
                        client_id: clientId || undefined,
                    }}
                    name="last_editor"
                    label="Edited by"
                    useIdValue
                    className={classes.input}
                />
            )}
            {clientHasJobNumberConfiguration && !isDashboard && (
                <>
                    <DealAutocompleteWithSearch
                        name="dealId"
                        label="Deal Number"
                        useIdValue
                        additionalFilter={{
                            client_id: clientId || undefined,
                        }}
                        className={classes.input}
                    />
                    <NumberField
                        name="jobNumber"
                        label="Job Number"
                        className={classes.input}
                        min={0}
                        max={Number.MAX_SAFE_INTEGER}
                    />
                </>
            )}
            {(clientDepartmentValues?.length > 0)
            && !clientHasJobNumberConfiguration && !isDashboard && (
                <CustomFieldNamedAutocomplete
                    customFields={clientDepartmentValues}
                    name="custom_fields"
                    label="Department"
                    useIdValue
                    className={classes.input}
                />
            )}
            {clientIsAvionteBound && !isDashboard && isClosedStatus && (
                <>
                    <DayPickerField
                        name="pay_date"
                        format={shortDateFormat}
                        placeholder="Exported Date"
                        className={classes.input}
                        withKeyboard
                    />
                    <NumberField
                        name="batch_id"
                        label="Batch ID"
                        className={classes.input}
                        min={0}
                        max={Number.MAX_SAFE_INTEGER}
                    />
                </>
            )}
        </form>
    );
};
