import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';
import { useOfferLetterStyles } from '../../OfferLetterStyles';
import { IOfferLetter, OfferLetterStatusSlug } from '../../store/model';
import { ApproveOfferButton } from 'modules/offerLetter/components/ApproveOffer/ApproveOfferButton';
import { EditAndResendButton } from 'modules/offerLetter/components/OfferCard/EditAndResendButton';
import { RescindButton } from 'modules/offerLetter/components/OfferCard/RescindButton';
import { RejectOfferButton } from 'modules/offerLetter/components/RejectPayRangeOffer/RejectOfferButton';
import { useOfferManagePermission } from 'modules/offerLetter/hooks/useOfferManagePermission';
import { isPayRateApprovalStatus } from 'modules/offerLetter/store/helpers';

export interface IOfferLetterActionsProp {
    offerLetter: IOfferLetter;
    clientId: string | null;
}

const OfferLetterActions = ({ offerLetter, clientId }: IOfferLetterActionsProp) => {
    const classes = useOfferLetterStyles();
    const isPayApproval = isPayRateApprovalStatus(offerLetter.status.slug);
    const userCanApprovePayRate = useSelector(selectIsUserHasPermission(Permission.ApprovePayRate));

    const rescindStatuses = useMemo(() => {
        const statuses = [OfferLetterStatusSlug.Outstanding];
        statuses.push(OfferLetterStatusSlug.PendingManagerApproval);
        return statuses;
    }, []);

    const userHasPermission = useOfferManagePermission(offerLetter);
    if (!userHasPermission) {
        return null;
    }

    if (isPayApproval) {
        return (
            <Box className={classes.buttonWrapper}>
                {userCanApprovePayRate && offerLetter.status.slug === OfferLetterStatusSlug.PendingPayRateApproval ? (
                    <>
                        <ApproveOfferButton offerLetterIds={[offerLetter.id]} />
                        <RejectOfferButton offerLetterIds={[offerLetter.id]} />
                    </>
                ) : (
                    <EditAndResendButton offerLetter={offerLetter} />
                )}
            </Box>
        );
    }

    return (
        <Box className={classes.buttonWrapper}>
            {clientId && (
                <>
                    {rescindStatuses.includes(offerLetter.status.slug) && (
                        <RescindButton offerLetter={offerLetter} />
                    )}
                    {[
                        OfferLetterStatusSlug.Outstanding,
                    ].includes(offerLetter.status.slug) && (
                        <EditAndResendButton offerLetter={offerLetter} />
                    )}
                </>
            )}
        </Box>
    );
};

export default OfferLetterActions;
