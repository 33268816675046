import { Box } from '@material-ui/core';
import { PayrollButton } from 'modules/payrollProcessorHub/components/PayrollAction/PayrollButton';
import {
    ApproveButton,
    ApproveButtonBatch,
} from 'modules/payrollProcessorHub/components/SheetStatusAction/ApproveButton';
import { RejectButton, RejectButtonBatch } from 'modules/payrollProcessorHub/components/SheetStatusAction/RejectButton';
import { getSheetGroupId, useActivePayrollSheetStatus } from 'modules/payrollProcessorHub/store/helpers';
import { ISheetGroupIdWithClient, PayrollSheetTabs } from 'modules/payrollProcessorHub/store/model';
import {
    selectAllCheckedCalculationGroupsContainJobOrder,
    selectCheckedCalculationGroups,
    selectCheckedTimeAndExpenseSheetGroups,
    selectNoneCheckedCalculationGroupsContainJobOrder, selectPayrollFilter,
} from 'modules/payrollProcessorHub/store/selectors';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';
import { PayrollBatchButton } from '../PayrollAction/PayrollBatchButton';
import { selectIsLoadingCustomFields } from 'store/entities/customFields/selectors';
import { AviontePayrollDownloadButton } from '../PayrollAction/AviontePayrollDownloadButton';
import { AviontePayrollDownloadBatchButton } from '../PayrollAction/AviontePayrollDownloadBatchButton';
import { uniqBy } from 'lodash-es';
import { AvionteBatchRedownloadButton } from '../PayrollAction/AvionteBatchRedownloadButton';

export const PayrollSheetBatchActions = () => {
    const status = useActivePayrollSheetStatus();

    //Single time & expense sheet in one group
    const groups = useSelector(selectCheckedTimeAndExpenseSheetGroups);
    const groupIds = groups.map(group => getSheetGroupId(group));
    const groupWithClientIds: ISheetGroupIdWithClient[] = groups.map(group => ({
        timeSheetId: group.time_sheet_id,
        expenseSheetId: group.expense_sheet_id,
        clientId: group.client_id,
    }));
    //Several time & expense sheets in one group
    const calculationGroups = useSelector(selectCheckedCalculationGroups);
    const {
        payPeriodEnd,
        clientId,
        employeeId,
        assignmentId,
        payrollProcessorUserId,
        dealId,
        jobNumber,
        managerIds,
        custom_fields,
        last_editor,
        batch_id,
    } = useSelector(selectPayrollFilter);
    const shouldShowReexport = useMemo(() => {
        return clientId && batch_id && !(
            payPeriodEnd || employeeId || assignmentId || payrollProcessorUserId || dealId || jobNumber
            || (managerIds ?? []).length || custom_fields || last_editor);
    }, [payPeriodEnd,
        clientId,
        employeeId,
        assignmentId,
        payrollProcessorUserId,
        dealId,
        jobNumber,
        managerIds,
        custom_fields,
        last_editor,
        batch_id,
    ]);

    const uniqueClientsCount = useMemo(() => {
        return uniqBy(calculationGroups, 'client_id').length;
    }, [calculationGroups]);
    const userHasApprovePermission = useSelector(selectIsUserHasPermission(Permission.CanApprovePphSheet));
    const isLoading = useSelector(selectIsLoadingCustomFields);
    const shouldShowExport = useSelector(selectAllCheckedCalculationGroupsContainJobOrder)
        && !isLoading && uniqueClientsCount === 1;
    const shouldShowProcess = useSelector(selectNoneCheckedCalculationGroupsContainJobOrder) && !isLoading;

    return (
        <Box
            display="flex"
            flexDirection="row"
            height="max-content"
        >
            {status === PayrollSheetTabs.APPROVED && shouldShowProcess && (
                <>
                    {groupIds.length > 0 && (
                        <>
                            <PayrollButton groupIds={groupWithClientIds} />
                        </>
                    )}
                    {calculationGroups.length > 0 && (
                        <>
                            <PayrollBatchButton calculationGroups={calculationGroups} />
                        </>
                    )}
                </>
            )}
            {status === PayrollSheetTabs.APPROVED && shouldShowExport && (
                <>
                    {groupIds.length > 0 && (
                        <>
                            <AviontePayrollDownloadButton groupIds={groupWithClientIds} />
                        </>
                    )}
                    {calculationGroups.length > 0 && (
                        <>
                            <AviontePayrollDownloadBatchButton calculationGroups={calculationGroups} />
                        </>
                    )}
                </>
            )}
            {status === PayrollSheetTabs.SUBMITTED && userHasApprovePermission && (
                <>
                    {groupIds.length > 0 && (
                        <>
                            <RejectButton groupIds={groupIds} />
                            <Box m={1} />
                            <ApproveButton groupIds={groupIds} />
                        </>
                    )}
                    {calculationGroups.length > 0 && (
                        <>
                            <RejectButtonBatch calculationGroups={calculationGroups} />
                            <Box m={1} />
                            <ApproveButtonBatch calculationGroups={calculationGroups} />
                        </>
                    )}
                </>
            )}
            {status === PayrollSheetTabs.CLOSED && !isLoading && shouldShowReexport && (
                <AvionteBatchRedownloadButton
                    batchId={batch_id || ''}
                    clientId={clientId || ''}
                />
            )}
        </Box>
    );
};
