import React from 'react';
import clsx from 'clsx';
import { Box, Button, capitalize, Grid, GridProps } from '@material-ui/core';
import { Form, FormikProps } from 'formik';
import { ClientMultiselect } from 'modules/settings/common/components/ClientMultiselect';
import { useEditOfferLetterModalFormStyles } from './EditOfferLetterModalFormStyles';
import { IOfferLetterTemplateEditForm } from 'modules/settings/submodules/offerLetters/model';
import FormField from 'shared/components/formFields/FormField';
import FormSelect from 'shared/components/selects/FormSelect';
import { Status } from 'shared/models/Status';
import {
    UploadTemplateField,
} from 'modules/settings/submodules/offerLetters/components/OfferLetterTemplateForm/UploadTemplateField';
import {
    FileUploadInfo,
} from 'modules/settings/submodules/offerLetters/components/EditOfferLetterTemplate/FileUploadInfo';

const statuses = [
    Status.active,
    Status.inactive,
];

const getStatusKey = (status: Status) => status;
const getStatusText = (status: Status) => capitalize(status);

const gridItemProps: GridProps = {
    item: true,
    sm: 6,
    xs: 12,
};

export type EditOfferLetterModalFormProps = FormikProps<IOfferLetterTemplateEditForm> & {
    closeDialog: () => void;
};

export const EditOfferLetterModalForm = ({ closeDialog, initialValues }: EditOfferLetterModalFormProps) => {
    const classes = useEditOfferLetterModalFormStyles();

    return (
        <Form>
            <Box className={classes.formWrapper}>
                <Grid container spacing={2}>
                    <Grid {...gridItemProps}>
                        <FormField
                            outerLabel="Name"
                            id="edit_offer_letter_template_name"
                            name="name"
                            className={classes.input}
                        />
                    </Grid>

                    <Grid {...gridItemProps}>
                        <FormSelect
                            id="edit_offer_letter_template_status"
                            outerLabel="Status"
                            options={statuses}
                            getKey={getStatusKey}
                            getText={getStatusText}
                            name="status"
                            className={classes.input}
                        />
                    </Grid>

                    <Grid {...gridItemProps} sm={12}>
                        <ClientMultiselect
                            // hack: force reset inner state depending on initial values
                            key={`clients_${JSON.stringify(initialValues)}`}
                            id="edit_offer_letter_template_clients"
                            outerLabel="Clients"
                            allClientsFieldName="allClients"
                            name="clients"
                            className={clsx(classes.input)}
                        />
                    </Grid>

                    <Grid {...gridItemProps} sm={12}>
                        <FormField
                            outerLabel="Description"
                            id="edit_offer_letter_template_description"
                            name="description"
                            multiline
                            className={classes.input}
                        />
                    </Grid>

                    <Grid {...gridItemProps} sm={12}>
                        <UploadTemplateField
                            key={`clients_${JSON.stringify(initialValues)}`}
                            name="fileName"
                            className={classes.input}
                            fileTypes="application/pdf"
                            maxSizeMb={15}
                            text="Upload new version"
                            note="a file up to 15MB. The PDF MUST contain fillable form elements."
                            rejectMessage="Please upload a pdf file."
                            info={<FileUploadInfo />}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Box className={classes.actions}>
                <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={closeDialog}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    type="submit"
                >
                    Update
                </Button>
            </Box>
        </Form>
    );
};
