import React from 'react';
import { Box } from '@material-ui/core';

import { FilterSection } from 'modules/payrollProcessorHub/components/PayrollSheetsTablePage/components/FilterSection';
import { MobilePayrollFooter } from 'modules/payrollProcessorHub/components/PayrollSheetsTablePage/MobilePayrollFooter';
import SheetSummary from 'modules/payrollProcessorHub/components/SheetSummary/SheetSummary';
import { SheetTabTitle } from 'modules/payrollProcessorHub/components/SheetTitle/SheetTabTitle';
import { PayrollSheetTable } from 'modules/payrollProcessorHub/components/PayrollSheetTable/PayrollSheetTable';
import usePayrollSheetsTableStyles
    from 'modules/payrollProcessorHub/components/PayrollSheetsTablePage/styles/usePayrollSheetsTableStyles';

export const PayrollOverdueSheetsTablePage = () => {
    const classes = usePayrollSheetsTableStyles();

    return (
        <>
            <Box p={2}>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    mb={2}
                >
                    <SheetTabTitle />
                    <SheetSummary />
                </Box>

                <FilterSection mb={2} />
            </Box>

            <Box
                className={classes.flexTableWrapper}
            >
                <PayrollSheetTable />
            </Box>

            <MobilePayrollFooter />
        </>
    );
};
