import { IOfferLetter } from 'modules/offerLetter/store/model';
import { selectEmployeeProfilesByIds, selectOfferLetters } from 'modules/offerLetter/store/selectors';
import { IInfoItemData } from 'modules/subassignmentManagement/components/AssignmentInfo/EditableInfoBlock/model';
import { AssignmentInfoTitles } from 'modules/subassignmentManagement/components/AssignmentInfo/store/model';
import { selectSubassignmentManagementState } from 'modules/subassignmentManagement/store/selectors';
import { createSelector } from 'reselect';
import { ItemsById } from 'shared/models/ItemsById';
import { IUserInfo } from 'shared/models/User';
import { getUserName } from 'shared/utils/converters/user';
import { formatShortDate } from 'shared/utils/formatters/dateFormatter';
import { formatPayRate } from 'shared/utils/formatters/payRate';
import { formatPhoneNumber } from 'shared/utils/formatters/phoneNumberFormatter';
import { getFormattedAssignmentHireDate, isAssignmentActive } from 'shared/utils/helpers/assingmentsHelper';
import { getFormattedOfferLetterStartDate } from 'shared/utils/helpers/offerLettersHelper';
import { IStore } from 'store/configureStore';
import { selectClientPaySettings } from 'store/entities/clients/selectors/timeAndPaySelectors';
import { IAssignment } from 'store/entities/configuration/configurationModel';
import { selectAssignmentsById } from 'store/entities/configuration/configurationSelectors';
import { getCustomFieldValueGroupedByCustomFieldId, getFieldValueName } from 'store/entities/customFields/helpers';
import { selectCustomFieldsByIds, selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';
import { selectUsersById } from 'store/entities/users/selectors';
import { Nullable } from '../../../../../types/types';
import { getDayOfWeekName } from 'shared/utils/helpers/paySettingsHelpers';

export const selectDetailState = (state: IStore) =>
    selectSubassignmentManagementState(state).assignmentInfoCard;

export const selectDetailAssignmentId = (state: IStore) =>
    selectDetailState(state).detailId;

export const selectIsLoadingDetail = (state: IStore) =>
    selectDetailState(state).isLoading;

export const selectDetailAssignmentIsStillActive = createSelector(
    selectDetailAssignmentId,
    selectAssignmentsById,
    function (assignmentId: string, assignmentsById: ItemsById<IAssignment>): boolean {
        const assignment: Nullable<IAssignment> = assignmentsById[assignmentId];
        return Boolean(assignment) && isAssignmentActive(assignment);
    },
);

export const selectDetailAssignment = createSelector(
    selectDetailAssignmentId,
    selectAssignmentsById,
    (assignmentId, assignmentsByIds): IAssignment | undefined => assignmentsByIds[assignmentId],
);

const payRateOT = 1.5;
const payRateDT = 2;

const getUserInfoBlock = (userTitle: string, userInfo?: IUserInfo) => {
    return [
        {
            label: `${userTitle} name`,
            value: getUserName(userInfo),
        },
        {
            label: `${userTitle} job title`,
            value: userInfo?.job_title,
        },
        {
            label: `${userTitle} email`,
            value: userInfo?.email,
        },
    ].filter(block => block.value);
};

export const selectAssignmentInfo = createSelector(
    selectDetailAssignment,
    selectCustomFieldsByIds,
    selectCustomFieldValuesByIds,
    selectOfferLetters,
    selectUsersById,
    selectEmployeeProfilesByIds,
    selectClientPaySettings,
    (
        assignment,
        customFieldsByIds,
        customFieldValuesByIds,
        offerLetterById,
        usersByIds,
        employeeProfilesByIds,
        paySettings,
    ): {
        employeeName: string;
        baseInfo: IInfoItemData[];
        additionalInfo: IInfoItemData[];
    } => {
        const employee = usersByIds[assignment?.user_id || ''];
        const employeeProfile = employeeProfilesByIds[assignment?.employee_profile?.id || ''];
        const offerLetter = Object.values(offerLetterById)
            // @ts-ignore
            .find(profile => profile.user_id === assignment?.user_id) as IOfferLetter;
        const customFieldBlocks = Object.entries(getCustomFieldValueGroupedByCustomFieldId(
            assignment?.custom_field_value_ids || [],
            customFieldValuesByIds,
            getFieldValueName,
        )).map(([fieldId, fieldValues]) => {
            // @ts-ignore
            const value = fieldValues.filter(Boolean).join(', ');
            const field = customFieldsByIds[fieldId];
            return {
                label: field?.name,
                value,
                additionalData: {
                    customFieldType: field?.headway_connect_field?.key,
                },
            };
        });
        const sender = usersByIds[offerLetter?.creator_id || ''];
        const managers = (assignment?.managers || []).map(managerInfo => ({
            ...managerInfo,
            ...usersByIds[managerInfo.user_id],
        }));
        const singleManager = managers.length === 1;

        return {
            employeeName: `${getUserName(employee)}${(
                employeeProfile?.avionte_talent_id ?? employeeProfile?.prism_employee_id)
                ? `, ${employeeProfile?.avionte_talent_id ?? employeeProfile?.prism_employee_id}` : ''}`,
            baseInfo: [
                {
                    label: AssignmentInfoTitles.Email,
                    value: employee?.email,
                },
                {
                    label: AssignmentInfoTitles.Placement,
                    value: assignment?.avionte_placement_id ?? '',
                },
                {
                    label: AssignmentInfoTitles.Phone,
                    value: formatPhoneNumber(employee?.cell_phone),
                },
                {
                    label: AssignmentInfoTitles.Address,
                    value: employeeProfile ? `${employeeProfile?.address} ${employeeProfile?.address2} \n${employeeProfile?.city}, ${employeeProfile?.state} ${employeeProfile?.zip_code}`.trim() : '',
                },
            ].filter(block => block.value),
            // @ts-ignore
            additionalInfo: [
                {
                    label: AssignmentInfoTitles.StartDate,
                    value: getFormattedAssignmentHireDate(assignment) || getFormattedOfferLetterStartDate(offerLetter),
                },
                {
                    label: AssignmentInfoTitles.BaseRate,
                    value: assignment ? formatPayRate(
                        {
                            pay_rate_value: assignment?.pay_rate_value,
                            pay_rate_type: assignment?.pay_rate_type,
                        },
                        paySettings?.pay_period_type,
                    ) : '',
                },
                {
                    label: AssignmentInfoTitles.EndDate,
                    value: assignment?.end_date ? formatShortDate(assignment?.end_date) : '',
                },
                {
                    label: AssignmentInfoTitles.AcceptedDate,
                    value: offerLetter?.accepted_at ? formatShortDate(offerLetter?.accepted_at) : '',
                },
                ...managers.map(
                    managerInfo => getUserInfoBlock(singleManager ? 'Manager' : `Manager ${managerInfo.manager_level}`, managerInfo),
                ).flat(),
                ...customFieldBlocks,
                {
                    label: AssignmentInfoTitles.OTPayRate,
                    value: assignment ? formatPayRate(
                        {
                            // @ts-ignore
                            pay_rate_value: assignment?.pay_rate_value * payRateOT,
                            pay_rate_type: assignment?.pay_rate_type,
                        },
                        paySettings?.pay_period_type,
                    ) : '',
                },
                {
                    label: AssignmentInfoTitles.DTPayRate,
                    value: assignment ? formatPayRate(
                        {
                            // @ts-ignore
                            pay_rate_value: assignment?.pay_rate_value * payRateDT,
                            pay_rate_type: assignment?.pay_rate_type,
                        },
                        paySettings?.pay_period_type,
                    ) : '',
                },
                {
                    label: AssignmentInfoTitles.EmployeeType,
                    value: offerLetter?.employment_type ? offerLetter.employment_type : '',
                },
                {
                    label: AssignmentInfoTitles.PayPeriod,
                    value: paySettings
                        // @ts-ignore
                        ? `${getDayOfWeekName(paySettings?.pay_period_start_day)} - ${getDayOfWeekName(paySettings?.pay_period_end_day)}` : '',
                },
                ...getUserInfoBlock('Sender', sender),
            ].filter(block => block.value),
        };
    },
);
