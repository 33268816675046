import React from 'react';
import { Box } from '@material-ui/core';

import { FilterSection } from './components/FilterSection';
import { MobilePayrollFooter } from './MobilePayrollFooter';

import { SheetTabTitle } from '../SheetTitle/SheetTabTitle';
import { PayrollSheetTable } from '../PayrollSheetTable/PayrollSheetTable';

import usePayrollSheetsTableStyles
    from 'modules/payrollProcessorHub/components/PayrollSheetsTablePage/styles/usePayrollSheetsTableStyles';

export const PayrollMissingSheetsTablePage = () => {
    const classes = usePayrollSheetsTableStyles();

    return (
        <>
            <Box p={2}>
                <SheetTabTitle />

                <FilterSection mb={2} />
            </Box>

            <Box
                className={classes.flexTableWrapper}
            >
                <PayrollSheetTable />
            </Box>

            <MobilePayrollFooter />
        </>
    );
};
