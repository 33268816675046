import React from 'react';
import { AttachmentsPreviewSecure } from 'shared/components/attachments/AttachmentsPreviewSecure';
import { useLoadSecureSheetAttachments } from 'shared/components/attachments/hooks';
import { IAttachment } from 'shared/models/Attachments';

export interface IAttachmentsPreviewProps {
    open: boolean;
    onClose: () => void;
    onDelete?: (file: IAttachment) => void;
    files: IAttachment[];
}

export default function EntryAttachmentsPreview(props: IAttachmentsPreviewProps) {
    const files = props.files;
    useLoadSecureSheetAttachments(files);
    return (<AttachmentsPreviewSecure {...props} />);
}
