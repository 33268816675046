import React from 'react';
import { FormikProps } from 'formik';
import { Box, Button, CircularProgress } from '@material-ui/core';
import DayPickerField from 'shared/components/formFields/DayPickerField';
import FormField from 'shared/components/formFields/FormField';
import { IEmployeeProfileFormValues } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/form/model';
import NumberField from 'shared/components/formFields/NumberField';
import AddressAutocompleteField
    from 'shared/components/formSpecialFields/addressAutocompleteField/AddressAutocompleteField';
import { IAddressComponents } from 'shared/components/formSpecialFields/addressAutocompleteField/models';
import { StateCodeSelect } from 'shared/components/selects/StateCodeSelect';
import { shortDateFormat } from 'shared/models/Dates';
import { formBasicSpacing, useStyles } from './styles';
import { Alert } from '@material-ui/lab';

export interface IEmployeeProfileFormProps extends FormikProps<IEmployeeProfileFormValues> {
    isLoading?: boolean;
    isDisabled?: boolean;
}

export function EmployeeProfileForm({
    handleSubmit,
    setFieldValue,
    isLoading = false,
    isDisabled = false,
}: IEmployeeProfileFormProps) {
    const classes = useStyles();
    const onAddressSelect = (address: IAddressComponents) => {
        setFieldValue('county', address.county);
        setFieldValue('state', address.stateCode);
        setFieldValue('city', address.city);
        setFieldValue('zip_code', address.zip_code);
        setFieldValue('address', `${address.street_number} ${address.street}`);
    };

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Box display="flex">
                <FormField
                    name="first_name"
                    label="First Name"
                    className={classes.input}
                />

                <FormField
                    name="last_name"
                    label="Last Name"
                    className={classes.input}
                />
            </Box>

            <DayPickerField
                name="birthday"
                label="Date of Birth"
                className={classes.input}
                format={shortDateFormat}
                placeholder={shortDateFormat}
                variant="dialog"
                disableFuture
                withKeyboard
            />

            <NumberField
                name="ssn"
                label="Social Security Number"
                className={classes.input}
                type="password"
            />

            <NumberField
                name="ssn_confirmation"
                type="password"
                label="Confirm Social Security Number"
                className={classes.input}
            />

            <AddressAutocompleteField
                name="address"
                label="Address"
                className={classes.input}
                onAddressSelect={onAddressSelect}
            />

            <FormField
                name="address2"
                label="Address 2"
                className={classes.input}
            />

            <Box display="flex">
                <FormField
                    name="county"
                    label="County"
                    className={classes.input}
                />

                <Box width="50%">
                    <StateCodeSelect
                        name="state"
                        label="State"
                        className={classes.input}
                    />
                </Box>
            </Box>

            <Box display="flex">
                <FormField
                    name="city"
                    label="City"
                    className={classes.input}
                />

                <FormField
                    name="zip_code"
                    label="Zip code"
                    className={classes.input}
                />
            </Box>

            <Box m={formBasicSpacing}>
                <Alert severity="warning" icon={false}>
                    <Box>
                        <strong className={classes.warningNoteStrong}>
                            NOTE:
                        </strong>
                        Please make sure you used your legal first and last name.
                    </Box>
                </Alert>
            </Box>

            <Box p={2} pb={5}>
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    disabled={isLoading || isDisabled}
                    data-testid="create-profile-button"
                >
                    Complete Registration
                    {isLoading && (
                        <Box ml={1} mb={-0.5}>
                            <CircularProgress color="inherit" size={16} />
                        </Box>
                    )}
                </Button>
            </Box>
        </form>
    );
}
