import { Box, Typography } from '@material-ui/core';
import { WorkOutlined } from '@material-ui/icons';
import { OnboardingHeader } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/OnboardingHeader';
import { useOfferLetterStyles } from 'modules/offerLetter/OfferLetterStyles';
import React from 'react';
import { useIsMobile } from '../../../../shared/utils/hooks/media';

export const OnboardingStep = () => {
    const isMobile = useIsMobile();
    const classes = useOfferLetterStyles({ isMobile });

    return (
        <Box className={classes.onboardingContainer}>
            <OnboardingHeader
                icon={<WorkOutlined fontSize="small" className={classes.headerIcon} />}
                title="3. Complete Onboarding in Avionte"
                navButtonText="Complete Profile"
            />
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="10%"
                flexGrow={1}
            >
                <Typography variant="overline">
            Thank you for completing your registration for Headway Connect. Check your email for onboarding next steps.
            Please click on the button above to complete your profile.
                </Typography>
            </Box>
        </Box>
    );
};
