import { max } from 'lodash-es';
import {
    IGroupedSheetCalculation,
    IPayrollProcessorFilters,
    IPayrollSheetSummary,
    IPostPayroll,
    IPrePayroll,
    ISheetCalculationBatch,
    ISheetGroupId,
    PayrollSheetTabs,
} from 'modules/payrollProcessorHub/store/model';
import { createSelector } from 'reselect';
import { IEntity } from 'shared/models/Entity';
import { IPaginationState } from 'shared/models/IPaginationResponse';
import { ItemsById } from 'shared/models/ItemsById';
import { EntryType, QuantityType } from 'shared/models/sheet/Sheet';
import { totalDollars } from 'shared/utils/counters/dollarCounter';
import { totalTime } from 'shared/utils/counters/timeCounter';
import { IStore } from 'store/configureStore';
import { selectAllClientsById, selectClientById } from 'store/entities/clients/selectors/clientsSelectors';
import { selectClientHasJobNumberConfiguration } from 'store/entities/clients/selectors/timeAndPaySelectors';
import {
    selectAssignmentsById,
    selectSubassignmentsByIds,
} from 'store/entities/configuration/configurationSelectors';
import { ICustomField, ICustomFieldValue } from 'store/entities/customFields/model';
import {
    selectCustomFields,
    selectCustomFieldsList,
    selectAllCustomFieldValues,
    selectCustomFieldValuesByIds,
} from 'store/entities/customFields/selectors';
import {
    ICalculation,
} from 'store/entities/timesheet/models/Calculation';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { selectAllEntries, selectExpensesSheetsByIds, selectTimeSheetsByIds } from 'store/entities/timesheet/selectors';
import { selectUsersById } from 'store/entities/users/selectors';
import { itemsByIds } from 'store/reducerUtils';
import { getInfinityScrollSelectors } from 'store/utils/infinityScroll/selectors';
import { IPayrollSheetGroupedRow } from '../components/PayrollSheetTable/model';
import {
    checkIfAllGroupEntriesHasJobOrders,
    checkIfNoneGroupEntriesHasJobOrders,
    generateGroupRowId,
    getUniqValues,
} from './helpers';
import { defaultMileageRate } from 'shared/models/Miles';

export const selectPayrollProcessorHubState = (state: IStore) => state.modules.payrollProcessorHub;
export const selectPayrollActiveTab = (state: IStore): PayrollSheetTabs =>
    selectPayrollProcessorHubState(state).filter.status || PayrollSheetTabs.ALL;
export const selectPayrollFilter = (state: IStore): IPayrollProcessorFilters =>
    selectPayrollProcessorHubState(state).filter;
export const selectGroupedSheetsPagination = (state: IStore): IPaginationState =>
    selectPayrollProcessorHubState(state).groupedSheets.pagination;
export const selectGroupedSheetsCalculations = (state: IStore): IGroupedSheetCalculation[] =>
    selectPayrollProcessorHubState(state).groupedSheets.groups;
export const selectSheetsGroupById = (groupId: ISheetGroupId) =>
    (state: IStore) =>
        selectGroupedSheetsCalculations(state).find(
            group => group.time_sheet_id === groupId.timeSheetId && group.expense_sheet_id === groupId.expenseSheetId,
        );
export const selectSheetsGroupsByIds = (groupIds: ISheetGroupId[]) => (state: IStore): IGroupedSheetCalculation[] =>
    groupIds.map(id => selectSheetsGroupById(id)(state)).filter(item => Boolean(item)) as IGroupedSheetCalculation[];
export const selectGroupedSheetsSummary = (state: IStore): IPayrollSheetSummary | null =>
    selectPayrollProcessorHubState(state).sheetSummary.summary;
export const selectIsLoadingSheetEditInfo = (state: IStore) =>
    selectPayrollProcessorHubState(state).sheetEditInfo.isLoading;
export const selectPayrollPayPeriods = (state: IStore) =>
    selectPayrollProcessorHubState(state).payPeriods;

export const selectPayrollPayPeriodsLoading = (state: IStore) =>
    selectPayrollProcessorHubState(state).payPeriodsLoading;

/**
 * Selector for getting processing state of payroll request
 * @param state
 * @return {boolean}
 */
export const selectIsPayRollProcessing = (state: IStore): boolean =>
    selectPayrollProcessorHubState(state).payRoll.isProcessing
    || selectPayrollProcessorHubState(state).payRoll.isExporting
    || false;

export const selectIsPayRollExporting = (state: IStore): boolean =>
    selectPayrollProcessorHubState(state).payRoll.isExporting
    || selectPayrollProcessorHubState(state).payRoll.isDownloading
    || false;

/**
 * Get availability of next payroll list pagination page
 * @param state
 * @return {boolean}
 */
export const selectPayrollProcessorSheetsHasNextPage = (state: IStore) => {
    const pagination = selectGroupedSheetsPagination(state);
    if (pagination.page_size) {
        return (pagination.total_items / pagination.page_size) > pagination.page;
    }
    return false;
};

/**
 * Get loading state for pre-initialize report
 * @param state
 * @return {boolean}
 */
export const selectIsPreInitializeReportLoading = (state: IStore) =>
    selectPayrollProcessorHubState(state).payRoll.isPreInitializeReportLoading || false;

/**
 * Get pre-initialize report
 * @param state
 */
export const selectPrePayrollReport = (state: IStore): IPrePayroll =>
    selectPayrollProcessorHubState(state).payRoll.prePayrollReport;

/**
 * Get post-processing report
 * @param state
 */
export const selectPostPayrollReport = (state: IStore): IPostPayroll =>
    selectPayrollProcessorHubState(state).payRoll.postPayrollReport;

/**
 * Get an attribute meaning that the missing sheet has changes and will be moved to overdue status in PPH
 *
 * Select all group related entries and count total time & expenses for missing sheets.
 * @param timeSheetIds string[]
 * @param expenseSheetIds string[]
 *
 * @return (state) => boolean
 */
export const selectIsEditedMissingSheetsHasChanges = (
    timeSheetIds: string[],
    expenseSheetIds: string[],
) => (state: IStore): boolean => {
    const sheetGroupEntries = selectAllEntries(state).filter(
        entry => entry.data?.entry_type !== QuantityType.TIME_BREAK
            && ((entry.entry_type === EntryType.TIME && timeSheetIds.includes(entry.sheet_id))
                    || (entry.entry_type === EntryType.EXPENSE && expenseSheetIds.includes(entry.sheet_id))),
    );
    const hasWorkedTime = totalTime(sheetGroupEntries) > 0;
    const hasExpenses = totalDollars(sheetGroupEntries, defaultMileageRate) > 0;

    const timeSheetsByIds = selectTimeSheetsByIds(state);
    const expenseSheetsByIds = selectExpensesSheetsByIds(state);
    const sheetStatusNames = new Set(
        [
            ...timeSheetIds.map(id => timeSheetsByIds[id]).filter(Boolean),
            ...expenseSheetIds.map(id => expenseSheetsByIds[id]).filter(Boolean),
        ].map(sheet => sheet?.status?.name).filter(Boolean),
    );

    return sheetStatusNames.has(StatusNames.WORKING) && (hasWorkedTime || hasExpenses);
};

/**
 * Check that any of calculations in table has job number
 */
export const selectCalculationsHasJobNumbers = createSelector(
    selectGroupedSheetsCalculations,
    groups => {
        return groups.some(group => group.job_number_id);
    },
);

/**
 * Check that any of calculations in table has files
 */
export const selectCalculationsHasFiles = createSelector(
    selectGroupedSheetsCalculations,
    groups => {
        return groups.some(group => group.time_files);
    },
);

/**
 * Check that selected in filter client has job number configuration
 * @param state
 */
export const selectPPHFilterClientHasJobNumberConfiguration = (state: IStore): boolean => {
    const { clientId } = selectPayrollFilter(state);
    if (clientId) {
        return selectClientHasJobNumberConfiguration(clientId)(state) || false;
    }
    return false;
};

export const selectPPHFilterClientHasAvionteConfiguration = (state: IStore): boolean | undefined => {
    const { clientId } = selectPayrollFilter(state);
    if (clientId) {
        const client = selectClientById(clientId)(state);
        if (client) {
            return !!client?.avionte_company_id;
        }
    }
    return undefined;
};

export const selectCheckedTimeAndExpenseSheets = createSelector(
    selectPayrollProcessorHubState,
    pphState => pphState.checkedTimeExpenseCalculationGroups,
);

export const selectCheckedTimeAndExpenseSheetGroupIds = createSelector(
    selectCheckedTimeAndExpenseSheets,
    checkedItems => Object
        .entries(checkedItems)
        .filter(([_, isChecked]) => isChecked)
        .map(([id, _]) => id),
);

export const selectCheckedTimeAndExpenseSheetGroups = createSelector(
    selectCheckedTimeAndExpenseSheetGroupIds,
    selectGroupedSheetsCalculations,
    (ids, groupedCalculations) =>
        groupedCalculations.filter(group => ids.includes(generateGroupRowId(group))),
);

export const selectGroupedCalculationTableState = createSelector(
    selectPayrollProcessorHubState,
    pphState => pphState.groupedSheetCalculationTable,
);
export const groupedCalculationsInfinityTableSelectors = getInfinityScrollSelectors<ISheetCalculationBatch>(
    selectGroupedCalculationTableState,
);

export const selectPositionCustomFields = createSelector(
    selectCustomFieldsList,
    (customFields): ICustomField[] => customFields.filter(field => field.prism_field?.key === 'position'),
);
export const selectLocationCustomFields = createSelector(
    selectCustomFieldsList,
    (customFields): ICustomField[] => customFields.filter(field => field.prism_field?.key === 'location'),
);
const filterCustomFieldValueByType = (positionCustomFields, customFieldValuesByIds): ItemsById<ICustomFieldValue> => {
    const positionCustomFieldsIds = positionCustomFields.map(field => field.id);
    const positionValues = Object.values(customFieldValuesByIds as ItemsById<ICustomFieldValue>)
        .filter((value: ICustomFieldValue) => positionCustomFieldsIds.includes(value?.custom_field_id));
    return itemsByIds(positionValues as IEntity[]);
};
export const selectPositionCustomFieldValuesByIds = createSelector(
    selectPositionCustomFields,
    selectCustomFieldValuesByIds,
    filterCustomFieldValueByType,
);
export const selectLocationCustomFieldValuesByIds = createSelector(
    selectLocationCustomFields,
    selectCustomFieldValuesByIds,
    filterCustomFieldValueByType,
);

export const selectGroupedCalculationsRows = createSelector(
    groupedCalculationsInfinityTableSelectors.selectItems,
    selectUsersById,
    selectAllClientsById,
    (
        groupedCalculations,
        usersByIds,
        clientsById,
    ): IPayrollSheetGroupedRow[] => {
        return groupedCalculations.map(group => {
            const allCalculations = [...group.time_calculations, ...group.expense_calculations] as Array<ICalculation>;
            const userTypes = getUniqValues(allCalculations, 'job_number_user_type_name');
            const periodType = getUniqValues(allCalculations, 'period_type')[0];
            const approvedLevels = getUniqValues(allCalculations, 'approved_level') as unknown as number[];

            return {
                ...group,
                client: clientsById[group.client_id || ''],
                employee: usersByIds[group.user_id || ''],
                approvedLevel: max(approvedLevels) as number || 0,
                userTypes,
                periodType,
            };
        });
    },
);

/**
 * Check that any of calculations in table has multiple approvers
 */
export const selectCalculationsHasMultipleApprovers = createSelector(
    selectGroupedCalculationsRows,
    selectSubassignmentsByIds,
    (sheetRows, subassignmentsByIds) => {
        return sheetRows?.some(row => {
            const allCalculations = [...row.time_calculations, ...row.expense_calculations] as Array<ICalculation>;
            const subassignmentIds = getUniqValues(allCalculations, 'subassignment_id');
            return subassignmentIds.map(id => subassignmentsByIds[id]).some(
                subassignment => subassignment?.managers?.length > 1,
            );
        });
    },
);

const findGroupById = (id: string | null, groups: ISheetCalculationBatch[]) => groups.find(group => group.id === id);

export const selectGroupedCalculationsRowsByIds = createSelector(
    selectGroupedCalculationsRows,
    (rows: Array<IPayrollSheetGroupedRow>): Record<string, IPayrollSheetGroupedRow> => (
        rows.reduce(
            (
                memo: Record<string, IPayrollSheetGroupedRow>,
                row: IPayrollSheetGroupedRow,
            ) => ({
                ...memo,
                [row.id]: row,
            }),
            {},
        )
    ),
);

export const selectCheckedCalculationGroupsState = createSelector(
    selectPayrollProcessorHubState,
    pphState => pphState.checkedCalculationGroups,
);

export const selectCheckedCalculationGroups = createSelector(
    selectCheckedCalculationGroupsState,
    groupedCalculationsInfinityTableSelectors.selectItems,
    (checkedState, calculationGroups) => calculationGroups.filter(group => checkedState[group.id]),
);

export const selectAllCheckedCalculationGroupsContainJobOrder = createSelector(
    selectCheckedCalculationGroups,
    selectCustomFields,
    selectAllCustomFieldValues,
    selectAssignmentsById,
    (currentGroups, fields, customFieldValues, assignments) =>
        checkIfAllGroupEntriesHasJobOrders(currentGroups, fields, customFieldValues, assignments),
);

export const selectNoneCheckedCalculationGroupsContainJobOrder = createSelector(
    selectCheckedCalculationGroups,
    selectCustomFields,
    selectAllCustomFieldValues,
    selectAssignmentsById,
    (currentGroups, fields, customFieldValues, assignments) =>
        checkIfNoneGroupEntriesHasJobOrders(currentGroups, fields, customFieldValues, assignments),
);

export const selectDetailCalculationGroupId = createSelector(
    selectPayrollProcessorHubState,
    pphState => pphState.detailGroupId,
);

export const selectDetailCalculationGroup = createSelector(
    selectDetailCalculationGroupId,
    groupedCalculationsInfinityTableSelectors.selectItems,
    findGroupById,
);
export const selectCalculationGroupApprovalsByIds = createSelector(
    selectPayrollProcessorHubState,
    pphState => pphState.approvalsByGroupId,
);

export const selectIsLoadingEditCalculationGroup = createSelector(
    selectPayrollProcessorHubState,
    pphState => pphState.isLoadingEditGroupInfo,
);

export const selectEditCalculationGroupId = createSelector(
    selectPayrollProcessorHubState,
    pphState => pphState.editGroupId,
);

export const selectEditCalculationGroup = createSelector(
    selectEditCalculationGroupId,
    groupedCalculationsInfinityTableSelectors.selectItems,
    findGroupById,
);

export const selectPdfDownloadingIds = createSelector(
    selectPayrollProcessorHubState,
    state => state.pdfDownloadingItemsIds,
);

export const selectGroupedSheetsTotalCount = createSelector(
    selectGroupedSheetsPagination,
    groupedCalculationsInfinityTableSelectors.selectTotalItems,
    (singleSheetGroupPaginationState, severalSheetGroupTotalCount) =>
        singleSheetGroupPaginationState.total_items || severalSheetGroupTotalCount || 0,
);

export const selectPayrollProcessorSort = (state: IStore) =>
    selectPayrollProcessorHubState(state).payrollProcessorSort;
