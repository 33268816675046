import { useDispatch, useSelector } from 'react-redux';
import { selectIsPayRollExporting } from '../../store/selectors';
import { Button } from '@material-ui/core';
import React, { useCallback } from 'react';
import { batchAvionteDownload } from '../../store/actions';

export interface IAvionteBatchRedownloadButtonProps {
    clientId: string,
    batchId: string,
}

export const AvionteBatchRedownloadButton = ({ clientId, batchId }: IAvionteBatchRedownloadButtonProps) => {
    const isExporting = useSelector(selectIsPayRollExporting);
    const dispatch = useDispatch();
    const onRedownload = useCallback(() => {
        dispatch(batchAvionteDownload.init({
            client_id: clientId,
            batch_id: batchId,
        }));
    }, [dispatch, clientId, batchId]);

    return (
        <>
            {batchId && clientId && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onRedownload}
                    disabled={isExporting}
                >
                    Export batch
                </Button>
            )}
        </>
    );
};
