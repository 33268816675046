/* eslint-disable react/display-name */
import React, { useMemo } from 'react';
import clsx from 'clsx';
import { EmployeeHeaderCell } from 'modules/subassignmentManagement/components/SubassignmentTable/components/EmployeeHeaderCell';
import {
    SubassignmentPayRateCell,
} from 'modules/subassignmentManagement/components/SubassignmentTable/components/SubassignmentPayRateCell';
import { useSubAssignmentTableStyles } from 'modules/subassignmentManagement/components/SubassignmentTable/styles';
import { setDetailAssignmentId } from 'modules/subassignmentManagement/store/actions';
import { ISubassignmentRow } from 'modules/subassignmentManagement/components/SubassignmentTable/model';
import { useSelector } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { ITableGroup } from 'shared/components/table/GridTable/GridTableGroup';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { getUserName } from 'shared/utils/converters/user';
import { formatShortDate } from 'shared/utils/formatters/dateFormatter';
import { isAssignmentActive } from 'shared/utils/helpers/assingmentsHelper';
import { useIsMobile } from 'shared/utils/hooks/media';
import { useCheckedItemsStore } from 'shared/utils/hooks/useCheckedItems';
import { selectAssignmentsById } from 'store/entities/configuration/configurationSelectors';
import { ICustomField } from 'store/entities/customFields/model';
import {
    selectAnchorCustomFieldId,
    selectCustomFieldValuesByIds,
    selectFirstTwoRootCustomFields,
} from 'store/entities/customFields/selectors';
import { getCheckboxCell } from 'shared/components/table/Cells/checkBoxHelper';
import { setSubassignmentCheckedGroups, setSubassignmentCheckedItems } from 'modules/subassignmentManagement/components/SubassignmentTable/store/actions';
import {
    getCustomFieldsDisplayValue,
    selectIsAssignmentManagementSidebarOpened,
    selectSubassignmentCheckedGroups,
    selectSubassignmentCheckedItems,
} from 'modules/subassignmentManagement/components/SubassignmentTable/store/selectors';
import { EditItemButton } from 'modules/settings/common/components/EditItemButton/EditItemButton';
import { setEditSubassignmentId } from 'modules/subassignmentManagement/components/EditSubAssignment/store/actions';
import { selectUserById } from 'store/entities/users/selectors';

export const getCommonSubassignmentsCells = (
    tableClasses: ReturnType<typeof useDefaultTableStyles>,
    extensionCells: ICellInfo<ISubassignmentRow>[] = [],
    forSubassignment = true,
    compactColumns = false,
): ICellInfo<ISubassignmentRow>[] => {
    return [
        {
            key: 'employee',
            title: 'employee',
            render: function ({
                className,
                rowIndex,
                user_id,
                custom_field_value_ids,
                all_values_custom_field_ids,
            }: ISubassignmentRow) {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const user = useSelector(selectUserById(user_id));
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const customFieldValuesByIds = useSelector(selectCustomFieldValuesByIds);
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const anchorFieldId = useSelector(selectAnchorCustomFieldId);
                const anchorValue = getCustomFieldsDisplayValue(
                    anchorFieldId,
                    customFieldValuesByIds,
                    custom_field_value_ids || [],
                    all_values_custom_field_ids || [],
                );
                const value = forSubassignment ? anchorValue || `Sub-assignment #${rowIndex + 1}` : getUserName(user);
                return (
                    <PlainText className={className} value={value} />
                );
            },
            renderTitle: EmployeeHeaderCell,
        },
        ...extensionCells,
        {
            key: 'payRate',
            title: 'pay rate',
            width: compactColumns ? '120px' : '1fr',
            render: SubassignmentPayRateCell,
        },
        ...compactColumns ? [] : [
            {
                key: 'startDate',
                title: 'start date',
                render: ({ className, start_date }: ISubassignmentRow) => {
                    return (
                        <PlainText className={className} value={formatShortDate(start_date)} />
                    );
                },
            },
            {
                key: 'endDate',
                title: 'end date',
                width: '2fr',
                render: ({ className, end_date }: ISubassignmentRow) => {
                    return (
                        <PlainText className={className} value={end_date ? formatShortDate(end_date) : ''} />
                    );
                },
            },
            {
                key: 'lastUpdated',
                title: 'last updated',
                width: '2fr',
                render: ({ className, updated_at }: ISubassignmentRow) => {
                    return (
                        <PlainText className={className} value={formatShortDate(updated_at)} />
                    );
                },
            },
        ],
        {
            key: 'action',
            width: '56px',
            render: ({ className, id }: ISubassignmentRow) => {
                return (
                    <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                        <EditItemButton
                            id={id}
                            editAction={forSubassignment ? setEditSubassignmentId : setDetailAssignmentId}
                        />
                    </div>
                );
            },
        },
    ];
};

export const getLazyCustomFieldsCells = (customFields: ICustomField[] = []): ICellInfo<ISubassignmentRow>[] => {
    return customFields.map(field => ({
        key: `customField-${field.id}`,
        title: field.name,
        render: ({ className, custom_field_value_ids, all_values_custom_field_ids }: ISubassignmentRow) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const customFieldValuesByIds = useSelector(selectCustomFieldValuesByIds);
            const value = getCustomFieldsDisplayValue(
                field.id,
                customFieldValuesByIds,
                custom_field_value_ids || [],
                all_values_custom_field_ids || [],
            );

            return (
                <PlainText className={className} value={value || ''} />
            );
        },
    }));
};

export const useCells = (groups: ITableGroup<ISubassignmentRow>[]): ICellInfo<ISubassignmentRow>[] => {
    const compactColumns = useSelector(selectIsAssignmentManagementSidebarOpened);

    const firstTwoRootCustomFields = useSelector(selectFirstTwoRootCustomFields);
    const assignmentsByIds = useSelector(selectAssignmentsById);
    const customFieldCells = getLazyCustomFieldsCells(firstTwoRootCustomFields);
    const subAssignmentTableStyles = useSubAssignmentTableStyles();
    const tableClasses = useDefaultTableStyles();
    const isMobile = useIsMobile();
    const activeGroups = useMemo(() => {
        return groups.filter(group => {
            const assignment = assignmentsByIds[group.id];
            return assignment && isAssignmentActive(assignment);
        });
    }, [assignmentsByIds, groups]);
    const subassignments = useMemo(() => activeGroups.map(group => group.rows).flat(), [activeGroups]);
    const checkItemsActions = useCheckedItemsStore<ISubassignmentRow>(
        subassignments,
        selectSubassignmentCheckedItems,
        setSubassignmentCheckedItems,
    );
    const checkGroupsActions = useCheckedItemsStore<ITableGroup<ISubassignmentRow>>(
        activeGroups,
        selectSubassignmentCheckedGroups,
        setSubassignmentCheckedGroups,
    );
    const checkedAll = checkItemsActions.checkedAll || (subassignments.length === 0 && checkGroupsActions.checkedAll);
    const onCheckAll = (checked: boolean) => {
        checkGroupsActions.onCheckAll(checked);
        checkItemsActions.onCheckAll(checked);
    };
    const checkboxCell = getCheckboxCell<ISubassignmentRow>(
        checkItemsActions.checkedItems,
        checkItemsActions.onCheck,
        checkedAll,
        onCheckAll,
        tableClasses,
        isMobile,
        row => row.id,
        [subAssignmentTableStyles.subassignmentHeaderChekboxCell],
        [
            subAssignmentTableStyles.subassignmentCheckboxCell,
        ],
    );

    return [
        checkboxCell,
        ...getCommonSubassignmentsCells(
            tableClasses,
            customFieldCells,
            true,
            compactColumns,
        ),
    ];
};

export const useAssignmentCells = (rows: ISubassignmentRow[]): ICellInfo<ISubassignmentRow>[] => {
    const sidebarOpened = useSelector(selectIsAssignmentManagementSidebarOpened);
    const firstTwoRootCustomFields = useSelector(selectFirstTwoRootCustomFields);
    const customFieldCells = getLazyCustomFieldsCells(firstTwoRootCustomFields);
    const tableClasses = useDefaultTableStyles();
    const isMobile = useIsMobile();

    const checkItemsActions = useCheckedItemsStore<ISubassignmentRow>(
        rows,
        selectSubassignmentCheckedGroups,
        setSubassignmentCheckedGroups,
    );
    const checkboxCell = getCheckboxCell<ISubassignmentRow>(
        checkItemsActions.checkedItems,
        checkItemsActions.onCheck,
        checkItemsActions.checkedAll,
        checkItemsActions.onCheckAll,
        tableClasses,
        isMobile,
        row => row.id,
        [],
    );

    return [
        checkboxCell,
        ...getCommonSubassignmentsCells(
            tableClasses,
            customFieldCells,
            false,
            sidebarOpened,
        ),
    ];
};
