import axios, { AxiosRequestConfig, Canceler } from 'axios';

import { IPaginationResponse } from 'shared/models/IPaginationResponse';
import baseApi, { IApiResponse } from 'shared/utils/baseApi';
import {
    IGroupedSheetCalculationRequest,
    IGroupedSheetPayrollRequest,
    IGroupedSheetSummaryRequest,
    IPayrollSheetSummary,
    ISearchGroupedCalculationsResponse,
    ISheetGroupId,
    ISheetGroupIdRequest,
    IPrePayrollResponse,
    IPostPayrollResponse,
    IPayrollPayPeriod,
    IPayrollPayPeriodRequest,
    ISheetCalculationBatch,
    ISheetCalculationBatchPdfRequest,
    IPayrollExportPayload, IPayrollReDownloadParams,
} from 'modules/payrollProcessorHub/store/model';
import { getFileNameFromResponse } from '../../../shared/utils/converters/fileNameResponse';

const CancelToken = axios.CancelToken;
let cancel: Canceler;
let cancelBatch: Canceler;

export const payrollProcessorHubApi = {
    async getGroupedSheetCalculation(
        params: IGroupedSheetCalculationRequest = {},
    ): Promise<ISearchGroupedCalculationsResponse> {
        cancel && cancel();

        const response = await baseApi
            .post<IGroupedSheetCalculationRequest, ISearchGroupedCalculationsResponse>(
            'sheets/coordinator/payroll/calculations/grouped/search/coordinator',
            params,
            {
                cancelToken: new CancelToken((c: Canceler) => {
                    cancel = c;
                }),
            },
        );
        return response.data;
    },
    async getGroupedSheetCalculationBatches(
        params: IGroupedSheetCalculationRequest = {},
    ): Promise<IPaginationResponse<ISheetCalculationBatch>> {
        cancelBatch && cancelBatch();

        const response = await baseApi
            .post<IGroupedSheetCalculationRequest, IPaginationResponse<ISheetCalculationBatch>>(
            'payroll/calculations/grouped/batch/search',
            params,
            {
                cancelToken: new CancelToken((c: Canceler) => {
                    cancelBatch = c;
                }),
            },
        );
        return response.data;
    },
    async getSheetSummary(
        params: IGroupedSheetSummaryRequest = {},
    ): Promise<IPayrollSheetSummary> {
        const response = await baseApi
            .post<IGroupedSheetSummaryRequest, IPayrollSheetSummary>(
            'payroll/calculations/grouped/search/counters',
            params,
        );
        return response.data;
    },
    async initSheetGroupPayroll(
        params: IGroupedSheetPayrollRequest,
    ): Promise<IApiResponse<IPostPayrollResponse>> {
        return await baseApi
            .post<IGroupedSheetPayrollRequest, IPostPayrollResponse>(
            'payroll/calculations/payroll',
            params,
        );
    },
    async redownloadAviontePayrollFile(
        { client_id, batch_id }: IPayrollReDownloadParams,
    ): Promise<{
            blob: BlobPart,
            filename: string,
        }> {
        const response = await baseApi
            .get<BlobPart>(
            `payroll/calculations/grouped/export_avionte/${batch_id}`,
            { 'client_id': client_id },
            {
                responseType: 'blob',
            },
        );
        const filenamePart = getFileNameFromResponse(response);

        return {
            blob: response.data,
            filename: filenamePart,
        };
    },
    async getAvionteExportPayrollFile(
        params: IPayrollExportPayload,
    ): Promise<{
            blob: BlobPart,
            filename: string,
        }> {
        const response = await baseApi
            .post<IPayrollExportPayload, BlobPart>(
            'payroll/calculations/grouped/export_avionte',
            params,
            {
                responseType: 'blob',
            },
        );
        const filenamePart = getFileNameFromResponse(response);

        return {
            blob: response.data,
            filename: filenamePart,
        };
    },
    async getGroupedSheetPdf(
        params: ISheetGroupIdRequest,
    ) {
        const response = await baseApi
            .get<ISheetGroupId>(
            'payroll/calculations/grouped/pdf',
            params,
            {
                responseType: 'blob',
            },
        );
        return response.data;
    },

    async getGroupedSheetCalculationPdf(
        params: ISheetCalculationBatchPdfRequest,
    ) {
        const url = 'payroll/calculations/grouped/search/pdf';
        const config: AxiosRequestConfig = { responseType: 'blob' };
        const response = await (
            baseApi.post<ISheetCalculationBatchPdfRequest, BlobPart>(
                url,
                params,
                config,
            )
        );
        return response.data;
    },

    async getPreInitializeReport(
        params: IGroupedSheetPayrollRequest,
    ): Promise<IPrePayrollResponse> {
        const { data } = await baseApi
            .post<IGroupedSheetPayrollRequest, IPrePayrollResponse>(
            '/payroll/calculations/payroll/query_report',
            params,
        );
        return data;
    },
    async getPayPeriods(
        params: IPayrollPayPeriodRequest,
    ): Promise<IPayrollPayPeriod[]> {
        const { data } = await baseApi.get<{ pay_periods: IPayrollPayPeriod[] }>(
            '/payroll/calculations/grouped/pay_periods',
            params,
        );
        return data.pay_periods;
    },
};
